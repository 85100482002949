export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'Info Utente',
    settings: 'Impostazioni',
    reset_daily_items: 'Reset items giornalieri?',
    last_parcels: 'Ultimi colli',
    select_item: 'Seleziona item',
    select_workstation: 'Seleziona postazione',
    select_language: 'Seleziona lingua',
    support: 'Supporto',
    select_printer: 'Seleziona stampante',
    shipment_to_confirm: 'Spedizioni da confermare',
    sorting_details: 'Dettagli smistamento',
    inventories: 'Inventari',
    pin_modal: "Inserisci il pin per forzare l'associazione",
    shipments: 'Spedizioni',
  },
  fields: {
    current_location: 'Posizione Attuale',
    identified_item: 'Item Identificato',
    ignored_items: 'Item ignorati',
    error_items: 'Item in errore',
    warning_items: 'Item in warning',
    ignored_with_reason_items: 'Item ignorati con motivazione',
    umg: 'Umg',
    custom_class: 'Classe custom',
    product_code: 'Codice prodotto',
    as400username: 'Username AS400',
  },
  misc: {
    or: 'oppure',
    select_place: `Ciao {{user}}, seleziona i luoghi di appartenenza per proseguire`,
    back: 'Indietro',
    refresh: 'Ricarica',
    save: 'Salva',
    confirm: 'Conferma',
    confirm_continue: 'Conferma e prosegui',
    cancel: 'Annulla',
    close: 'Chiudi',
    user: 'Utente',
    workstation: 'Postazione',
    username: 'Username',
    name: 'Nome',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    no_carton_found: 'Nessun carton trovato',
    code: 'Codice',
    ean_code: 'Codice EAN',
    certilogo: 'Certilogo',
    production_order: 'Ordine di produzione',
    production_order_row: 'Riga ordine di produzione',
    production_order_sku: 'Sku ordine di produzione',
    enter_the_necessary_fields: 'Inserisci i campi necessari per iniziare l’operazione',
    associated_items: 'Item associati',
    associate: 'Associa',
    associated: 'Associato',
    verify: 'Verifica',
    reading: 'In lettura',
    association: 'Associazione',
    associations: 'Associazioni',
    waiting: 'In attesa',
    writing: 'Scrittura',
    writing_progress: 'Scrittura in corso',
    retry: 'Riprova',
    writing_association: 'Scrittura e Associazione',
    confirmed: 'Confermato',
    unable_to_write: `Impossibile scrivere il tag: {{code}} per il prodotto: {{productCode}}`,
    unable_to_get_next_epc: `Impossibile ottenere il prossimo epc del prodotto: {{productCode}}`,
    unable_to_connect_to_workstation: 'Impossibile connettersi alla postazione',
    tag_info: 'Tag Info',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pausa',
    clear: 'Clear',
    ready_to_receive: 'Pronto per ricezione',
    change_password: 'Cambia password',
    logout: 'Effettua logout',
    are_you_sure_to_logout: 'Vuoi procedere con il logout?',
    settings: 'Impostazioni',
    roles: 'Ruoli',
    email: 'Email',
    place: 'Luogo',
    items: 'Item',
    description: 'Descrizione',
    color: 'Colore',
    size: 'Taglia',
    part: 'Parte',
    total_reads: 'LETTURE TOTALI',
    total_ok: 'LETTURE OK',
    save_to_list: 'Salva nella lista',
    destination: 'Destinazione',
    origin: 'Origine',
    new_parcel: 'Nuovo Collo',
    partial_confirm: 'Conferma parziale',
    transaction_date: 'Data transazione',
    select_transaction_date: 'Seleziona una data transazione',
    status: 'Stato',
    parcel: 'Collo',
    read_parcel: 'Leggi Collo',
    parcels: 'Colli',
    shipment: 'Spedizione',
    creation_date: 'Data Creazione',
    search: 'Cerca',
    select_search: 'Cerca...',
    detail: 'Dettaglio',
    quantity: 'Quantità ({{quantity}})',
    only_quantity: 'Quantità',
    style: 'Modello',
    material: 'Materiale',
    shipment_code_auto: 'Codice Spedizione (auto)',
    parcel_code_auto: 'Codice Collo (auto)',
    next: 'Avanti',
    parcels_to_receive: 'Colli da ricevere',
    confirm_parcel: 'Conferma Collo',
    image_missing: 'IMMAGINE MANCANTE',
    image: 'Immagine',
    loading: 'Caricamento...',
    unknown_product: 'Prodotto sconosciuto',
    unknown_tag: 'Tag sconosciuto',
    invalid_tag: 'Tag non valido',
    stock: 'Stock',
    valid: 'Valido',
    unknown: 'Sconosciuti',
    actions: 'Azioni',
    reprint: 'Ristampa',
    product: 'Prodotto',
    product_items: 'Item del Prodotto',
    item_identifiers: 'Identificatori',
    zone: 'Zona',
    report_repair: 'Segnala Riparazione',
    reason: 'Motivazione',
    request_code_change: 'Richiedi Cambio Codice',
    enable_workstation_emulation: 'Abilita emulazione Workstation',
    enable_browser_print_emulation: 'Abilita emulazione Browser Print',
    filter: 'Filtro',
    language: 'Lingua',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Opzionale',
    phone: 'Telefono',
    version: 'Versione',
    printer: 'Stampante',
    new_shipment: 'Nuova Spedizione',
    upc: 'UPC',
    model_part_color_size: 'Modello / Parte / Colore / Taglia',
    confirm_shipment: 'Conferma Spedizione',
    receive: 'Ricevi',
    delete: 'Elimina',
    delete_association: 'Elimina Associazione',
    change: 'Cambia',
    from: 'Da',
    to: 'A',
    ignored: 'Ignorato',
    skipped: 'Saltato',
    ignored_with_reason: 'Ignorato con motivazione',
    yes: 'Sì',
    no: 'No',
    model: 'Modello',
    typology: 'Tipologia',
    no_result: 'Nessun risultato',
    expected: 'Attesi',
    read: 'Letti',
    missing: 'Mancanti',
    unexpected: 'Inattesi',
    unexpected_parcel: 'Colli inattesi',
    ignored_plural: 'Ignorati',
    serial: 'Seriale',
    priority: 'Priorità',
    group: 'Gruppo',
    required_quantity: 'Qt richiesta',
    sorted_quantity: 'Qt smistata',
    missing_quantity: 'Qt mancante',
    barcode_bc: 'Barcode BC',
    sorted_date: 'Data smistamento',
    customer: 'Nominativo cliente',
    list: 'Lista',
    list_position: 'Posizione lista',
    select_date: 'Seleziona data',
    item_info: 'Info prodotto',
    item_events: 'Eventi prodotto',
    operation_type: 'Tipo operazione',
    operation_place: 'Luogo operazione',
    loading_data: 'Attendi, dati in caricamento...',
    empty_item_events: 'Nessun evento trovato',
    show_more: 'Mostra di più',
    hide_more: 'Riduci',
    kill_tag: 'Kill Tag',
    item_order: 'Item ordine',
    item_row: 'Item riga',
    pack_code: 'Codice pacco',
    items_add_inventory_success: "Item aggiunti all'inventario",
    new: 'Nuova',
    send_reads: 'Invia letture',
    confirm_mission: 'Conferma missione',
    confirm_mission_text: 'Sei sicuro di voler confermare la missione?',
    parziali: 'Parziali',
    total_quantity: 'Quantità totale',
    accepted_quantity: 'Quantità accettata',
    country_check: 'Controllo paese',
    showcase_check: 'Controllo vetrina',
    attention: 'Attenzione',
    clear_items: 'Procedendo tutte le letture verranno rimosse, si desidera continuare?',
    add_partial: 'Attenzione',
    add_partial_text: 'Potrebbe essere necessario creare Missione per Controllo Qualità',
    add_partial_already_text:
      'Potrebbe essere necessario creare Missione per Controllo Qualità. UMG già lavorato. Vuoi sovrascrivere gli item letti?',
    error_cq_mission: 'Attenzione, creare Missione per Controllo Qualità',
    warning_zone_items: 'IN_ANOTHER_ZONE',
    internal_tag: 'Tag interno',
    external_tag: 'Tag esterno',
    error_tag: 'Errore tag',
    awaiting: 'In attesa',
    error_logs: 'Log degli errori',
    event_logs: 'Log degli eventi',
    box_closed: 'Collo chiuso',
    unexpected_list: 'Elenco item inattesi',
    pending_tags: 'Tag in sospeso',
    selected_zone: 'Zona selezionata',
    pricing: 'Pricing',
    send_to_sap: 'Invia a SAP',
    write_tag: 'Scrittura tag',
    shipping_date: 'Data spedizione',
    update_only_product: 'Item già associato ad un altro prodotto. Sei sicuro di volerlo sovrascrivere?',
    export: 'Esporta',
    select_zone: 'Seleziona zona',
    location_origin: 'Location di origine',
  },
  error: {
    error: 'Errore',
    errors: 'Errori',
    forbidden: 'Forbidden',
    not_found: 'Non trovato',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Hai letto troppi tag (attesi {{exptected}} letti {{read}})',
    too_many_checklist_types:
      'Più di un tipo di checklist trovato. Assicurati di selezionare colli con checklist dello stesso tipo.',
    certilogo_not_found: 'Certilogo "{{code}}" non trovato',
    ean_not_found: 'EAN "{{code}}" non trovato',
    production_order_not_found: 'Ordine di Produzione "{{code}}" non trovato',
    no_inbound_zones: 'Nessuna zona di Inbound',
    no_operation_config: 'Configurazione operazione non trovata',
    not_supported_reading_mode: 'Modalità di lettura non supportata',
    undefined_operation_config: 'La configurazione non è definita',
    certilogo_already_encoded: 'Certilogo "{{code}}" già encodato',
    tag_not_found: 'Tag non trovato',
    tag_unassociated: 'Tag non associato',
    missing_printer_id: 'Missing printer IP',
    tag_type_unexpected: 'Non è previsto un tag di questo tipo',
    tag_already_associated:
      'Il tag è già associato. È necessario disassociarlo prima di effettuare una nuova associazione',
    tags_not_associated: 'Uno o più tag non è associato ad alcun item',
    tags_mismatch: 'Il tag è associato ad un altro item',
    workstation_could_not_be_started: 'La workstation {{code}} non può essere avviata',
    authentication_failed: 'Autenticazione fallita',
    field_required: 'Il campo {{label}} è obbligatorio',
    unable_to_connect_to_rfid_reader: 'Impossibile avviare il reader. Verifica che il reader sia acceso',
    items_in_error_found: 'Trovati prodotti in errore',
    item_not_in_inbound: 'Prodotto non in inbound',
    item_not_in_stock: 'Prodotto non a stock',
    item_in_another_parcel: 'Prodotto in un altro collo',
    item_in_another_production_order: 'Prodotto in un altro ordine di produzione',
    connection: 'Errore di connessione',
    connection_details:
      'Il server non ha risposto. Controlla che la tua connessione internet e le eventuali configurazioni della tua VPN siano funzionanti.',
    field_invalid_default: 'Il valore predefinito "{{defaultValue}}" del campo {{field}} non è valido',
    field_invalid_default_from_endpoint: 'Non è stato possibile trovare un valore per il campo {{field}}',
    sorting_already_read: 'Articolo con seriale {{serial}} già smistato nel gruppo {{group}}',
    sorting_no_group: 'Articolo non smistabile',
    sorting_not_in_zone: "Articolo con seriale {{serial}} non smistabile. L'articolo è nella zona {{zone}}",
    sorting_unknown: 'Tag {{tag}} non valido',
    sorting_unexpected: 'Tag inatteso',
    no_item_configuration: 'Nessuna configurazione trovata',
    item_creation_error: "Errore nella creazione dell'item",
    couldn_find_production_order: "Non è stato possibile trovare l'ordine di produzione",
    couldn_find_production_order_row: "Non è stato possibile trovare la riga dell'ordine di produzione",
    missing_operation_option: 'Opzione operazione mancante: {{error}}',
    item_session_list_create: 'Lista non creata',
    item_session_list_create_session: 'Sessione non creata',
    item_session_list_confirm: 'Lista non confermata',
    item_session_list_confirm_session: 'Sessione non confermata',
    form_validation: 'Validazione form',
    form_validation_message: 'Errore nella compilazione del campo',
    select_place_error: 'Non è stato possibile salvare il luogo di appartenenza',
    self_user_data: 'Non è stato possibile salvare i tuoi dati',
    encoding_no_tags: 'Nessun tag ulteriore è stato letto. Operazione interrotta',
    only_virgin_tag: 'Solo tag vergini rilevati',
    tags_mismatch_error: 'Rilevare un tag per volta',
    tags_errors: 'Errori sui tag',
    value_not_valid: 'Il valore inserito non è valido',
    write_tag: 'Errore durante la scrittura del tag',
    WRONG_PRODUCTION_ORDER: 'Wrong Production Order',
    WRONG_PLACE: 'Wrong place',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: 'Production order row creation error',
    force_tag_associated: 'Tag già associato. Riassociare?',
    operation_confirm_error: "Errore nella conferma dell'operazione",
    missing_mandatory_qc: 'Controllo qualità assente. Procedere?',
    wrong_qc_status: 'Il prodotto è stato dichiarato KO. Procedere?',
    wrong_qc_size: 'Controllare taglia. Procedere?',
    wrong_qc_size_payload:
      'Controllare taglia. Taglia Encoding: {{productSize}} - Taglia Fisica: {{manualSize}}. Procedere?',
    wrong_qc_model: 'Controllare modello. Procedere?',
    start_checklist: 'Checklist non inizializzata',
    confirm_with_unexpected: 'Impossibile confermare con tag inattesi',
    confirm_with_overqty: 'Impossibile confermare con tag in eccesso',
    rfid_reader_initialization: "Errore sconosciuto durante l'inizializzazione del lettore RFID",
    remove_sap_item_error: "Impossibile rimuovere l'item",
    no_asn_selected: 'ASN non selezionato',
    submit_asn: 'Impossibile selezionare ASN',
    zbpNotFound: 'Servizio Zebra Browser Print non trovato. Assicurarsi che sia installato e in esecuzione',
    no_printer_selected: 'Nessuna stampante selezionata',
    zplTemplateNotDefined:
      'Template ZPL non definito. Si prega di impostare un codice template nell\'attributo "zpl-template-code" della configurazione dell\'operazione',
    no_print_template: 'Template di stampa non presente',
    wrong_tag: 'Tag errato',
    tag_error_or_not_authentic: 'Tag non autentico o errore autenticazione',
    authentication_timeout: 'Timeout autenticazione',
    tid_missing: 'Tid mancante per il tag letto',
    search_error: 'Errore di ricerca',
    no_parcel_found_by_identifier: 'Nessun Parcel trovato con questo identificativo',
  },
  messages: {
    no_operations_found: "Nessuna operazione trovata. Controlla i permessi dell'utente sul ControlPanel",
    no_workstations_found: 'Nessuna Postazione trovata',
    no_workstation_selected: 'Nessuna Postazione selezionata',
    are_you_sure_to_reset_daily_items: 'Sei sicuro di voler resettare gli item giornalieri?',
    bring_an_item_to_antenna: "Avvicina un prodotto all'antenna",
    press_start_to_read: 'Premi Start per iniziare \na leggere i prodotti',
    reading_in_progress: 'Lettura in corso',
    waiting_for_product: 'In attesa di lettura prodotto…',
    paste_comma_separeted_tags: 'Incolla i tags separati da virgola e premi Enter...',
    connection_workstation_progress: 'Connessione alle antenne in corso...',
    operation_success: 'Operazione completata con successo',
    outbound_success: 'Outbound completata con successo',
    inbound_success: 'Inbound completata con successo',
    pack_success: 'Packing completata con successo',
    transfer_to_zone_success: 'Trasferimento a Zona completato con successo',
    list_empty: 'Lista vuota',
    looks_like_anything_in_list: 'Sembra che la tua lista sia vuota',
    generic_success: '{{code}} completato con successo',
    generic_success_canceled: '{{code}} annullata con successo',
    generic_error: '{{code}} in errore',
    fill_all_fields_and_search_an_item_configuration: 'Riempi tutti i campi e cerca una configurazione',
    encoding_success: 'Item encodato con successo',
    encoding_success_none: 'Item già associato in precedenza',
    read_some_identifier_to_start_verification: 'Leggi tag per iniziare la verifica',
    warning_items_found: 'Trovati item in warning',
    are_you_sure_you_want_to_confirm: 'Sei sicuro di voler confermare?',
    item_session_list_confirm: 'Lista confermata correttamente',
    operation_in_progress: 'Operation in corso, attendere...',
    sorting_error_zones: 'Zona di origine non valorizzata, impossibile proseguire con la ventilation',
    connection_in_progress: 'Collegamento in corso...',
    checklist_there_are: 'Ci sono',
    checklist_missings: 'items mancanti',
    checklist_overqty: 'items in sovrabbondanza',
    checklist_unexpecteds: 'items inattesi',
    checklist_confirm_mismatch_question: 'Sicuri di voler confermare comunque?',
    force_update_content: "Sei sicuro di voler forzare l'invio a SAP?",
    connection_to_station_in_progress: 'Connessione alla postazione in corso',
    tag_killed: 'Tag killed',
    tag_deleted: 'Tag deleted',
    write_tag: 'Scrittura tag effettuata con successo',
    identifier_tag: 'Identifier Tag',
    digit_identifier_tag: 'Digit tag to write',
    target_epc: 'Target EPC',
    retry_write_tag: 'A writing error was encountered. Do you want to try writing the tag again?',
    ready: 'Pronto',
    offline: 'Off-line',
    not_ready: 'Non pronta',
    readyf: 'Pronta',
    nfcDecryption: 'Decrittazione tag NFC. Tenere il tag fermo, per favore',
  },
  placeholder: {
    search_shipment_parcel: 'Cerca Commessa o Collo',
    search_product_parcel: 'Cerca Prodotto o Collo',
  },
  confirm: {
    discrepancy_title: 'Collo con discrepanze',
    discrepancy_message: 'Trovate discrepanze nel collo. Sei sicuro di voler confermare?',
    confirm_delete_association: 'Sei sicuro di voler disassociare questo prodotto?',
    confirm_delete_operation_title: 'Cancella {{operation}}',
    confirm_delete_operation: "Sei sicuro di voler cancellare l'operazione? Gli elementi letti saranno perduti.",
    confirm_delete_item_association: 'Sei sicuro di voler disassociare questo item?',
    confirm_kill_tag: 'Sei sicuro di voler eliminare questo tag?',
    confirm_operation_title: 'Conferma {{operation}}',
    confirm_operation_text: 'Sei sicuro di voler confermare questa operazione?',
    confirm_operation_text_irreversible:
      'Sei sicuro di voler confermare questa operazione? Questa azione è irreversibile',
    confirm_operation_text_partial_confirm: 'Invio dati a JDE. Sei sicuro di voler continuare?',
    post_confirm_action_title: 'Prosegui',
    post_confirm_action_keep_input: "Operazione completata. Effettuare un'altra operazione?",
    post_confirm_action_change_input: "Operazione completata. Effettuare un'altra operazione?",
    confirm_close_asn_title: 'Conferma chiusura',
    confirm_close_asn_text:
      'Alcuni colli non sono stati ricevuti. Sei sicuro di voler FORZARE la chiusura della spedizione?',
    unpack: 'Collo già presente, vuoi procedere con l’unpack ed effettuare un nuovo packing?',
  },
  labels: {
    draft: 'Bozza',
    ready_to_send: 'Pronto per spedizione',
    send_await: 'In attesa',
    outbound_error: 'Errore Outbound',
    sent: 'Inviato',
    in_inbound: 'In Inbound',
    receive_await: 'In attesa',
    ready_to_receive: 'Da ricevere',
    inbound_error: 'Errore Inbound',
    received: 'Ricevuto',
    in_transit: 'In transito',
  },
  serverError: {
    //ZONE_MISMATCH_ERROR: "L'item non è localizzato nella zona di provenienza selezionata",
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'N. DDT',
    creation_date: 'Data creazione',
    capi_mdc: 'Capi MDC',
    qt_totale: 'Qt Totale',
    qt_accettata: 'Qt Accettata',
    qt_scartata: 'Qt Scartata',
    supplier: 'Fornitore',
    notes: 'Note',
  },
  custom: {
    countTBoxParcel: 'Collo',
    countTBoxDDT: 'DDT',
    BARCODE_READ: 'Barcode Read',
    RFID_START_READING: 'Start reading',
    RFID_STOP_READING: 'Stop reading',
    RESULT_EVENT: 'Result event',
    TRACE: 'Trace',
    INFO: 'Info',
    WARNING: 'Warning',
    ERROR: 'Error',
    deleteSession: 'Elimina Collo',
    deleteSessionMessage: 'Sei sicuro di voler eliminare il collo?',
    deleteList: 'Elimina DDT',
    deleteListMessage: 'Sei sicuro di voler eliminare il ddt?',
    WAM: 'SKU',
    sap_warning: 'SAP warning',
    total_list: 'Totale lista',
    total_parcel: 'Totale collo',
    order_number: 'Numero commessa',
    addon: 'Addon',
  },
  imbustatrice: {
    and: ' e ',
    unkwon_tag: 'Tag Sconosciuto',
    dynamic_tag: '{{role}} Tag',
    dynamic_tag_missing: 'Tag {{role}} non rilevato',
    too_many_dynamic_tags: 'Troppi tag {{role}} rilevati',
    dynamic_tag_already_associated: 'Tag {{role}} già associato',
    wrong_tag: 'Tag Sbagliato',
    missing_tag: 'Nessun tag letto',
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: 'Tag già associato',
    WRONG_QC_STATUS: 'Stato controllo qualità errato',
    WRONG_QC_SIZE: 'Taglia controllo qualità errata',
    WRONG_QC_MODEL: 'Modello controllo qualità errato',
    MISSING_MANDATORY_QC: 'Controllo qualità mancante',
    MISSING_PRODUCT_INFO: 'Informazioni sul prodotto mancanti',
    WRONG_TAG: 'Tag sbagliato',
    UNKNOWN_TAG: 'Tag sconosciuto',
    WRONG_PRODUCT: 'Prodotto sbagliato',
    MISSING_MANDATORY_TAG: 'Tag obbligatorio mancante',
    MISSING_OPTIONAL_TAG: 'Tag opzionale mancante',
    EXTRA_TAG: 'Extra tag',
    TAG_MISMATCH: 'Mancata corrispondenza del tag',
    PRODUCTIONORDERROW_MISMATCH: 'Associazione riga ordine di produzione non corrispondente',
    MISSING_SYSTEM_TAG: 'Tag sconosciuto',
  },
  statuses: {
    RESTORE_VARIANT: 'RIPRISTINARE TIPOLOGIA',
  },
}
