export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    select_item: 'Select Item',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    support: 'Support',
    select_printer: 'Select printer',
    shipment_to_confirm: 'Shipment to Confirm',
    sorting_details: 'Ventilation details',
    inventories: 'Inventories',
    pin_modal: 'Enter the pin to force association',
    shipments: 'Shipments',
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    ignored_items: 'Ignored Items',
    ignored_with_reason_items: 'Ignored with reason Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
    umg: 'Umg',
    custom_class: 'Custom class',
    product_code: 'Product code',
    as400username: 'Username AS400',
  },
  misc: {
    or: 'or',
    select_place: `Ciao {{user}}, select your places of origin to continue`,
    back: 'Back',
    refresh: 'Refresh',
    save: 'Save',
    confirm: 'Confirm',
    no_carton_found: 'No carton found',
    confirm_continue: 'Confirm and continue',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    code: 'Code',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    associated: 'Associated',
    verify: 'Verify',
    reading: 'Reading',
    processing: 'Processing',
    association: 'Association',
    associations: 'Associations',
    waiting: 'Waiting',
    writing: 'Writing',
    writing_progress: 'Writing in progress...',
    partial_confirm: 'Partial Confirm',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    transaction_date: 'Transaction date',
    select_transaction_date: 'Select transaction date',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    size: 'Size',
    part: 'Part',
    total_reads: 'TOTAL READS',
    total_ok: 'TOTAL OK',
    save_to_list: 'Save to list',
    destination: 'Destination',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    status: 'Status',
    parcel: 'Parcel',
    read_parcel: 'Read Parcel',
    parcels: 'Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    search: 'Search',
    select_search: 'Search...',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    only_quantity: 'Quantity',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    confirm_parcel: 'Confirm Parcel',
    image_missing: 'IMAGE MISSING',
    image: 'Image',
    loading: 'Loading...',
    unknown_product: 'Unknown Product',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Invalid Tag',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    reprint: 'Reprint',
    product: 'Product',
    product_items: 'Product Items',
    identifiers: 'Identifiers',
    zone: 'Zone',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    enable_workstation_emulation: 'Enable Workstation emulation',
    enable_browser_print_emulation: 'Enable Browser Print emulation',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    phone: 'Phone',
    version: 'Version',
    printer: 'Printer',
    new_shipment: 'New Shipment',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete: 'Delete',
    delete_association: 'Delete Association',
    change: 'Change',
    from: 'From',
    to: 'To',
    ignored: 'Ignored',
    skipped: 'Skipped',
    ignored_with_reason: 'Ignored with reason',
    yes: 'Yes',
    no: 'No',
    model: 'Model',
    typology: 'Tipo',
    no_result: 'No result',
    expected: 'Expected',
    read: 'Read',
    missing: 'Missing',
    unexpected: 'Unexpected',
    unexpected_parcel: 'Unexpected parcel',
    ignored_plural: 'Ignored',
    serial: 'Serial',
    priority: 'Priority',
    group: 'Group',
    required_quantity: 'Required qty',
    sorted_quantity: 'Ventilated qty',
    missing_quantity: 'Missing qty',
    barcode_bc: 'Barcode BC',
    sorted_date: 'Ventilation date',
    customer: 'Customer name',
    list: 'List',
    list_position: 'List Position',
    select_date: 'Select date',
    item_info: 'Item info',
    item_events: 'Item events',
    operation_type: 'Operation type',
    operation_place: 'Operation place',
    loading_data: 'Please wait, data is loading ...',
    empty_item_events: 'No events found',
    show_more: 'Show more',
    hide_more: 'Hide more',
    production_order: 'Production Order',
    production_order_row: 'Production Order Row',
    production_order_sku: 'Production Order Sku',
    kill_tag: 'Kill Tag',
    item_order: 'Item Order',
    item_row: 'Item Row',
    pack_code: 'Package Code',
    send_reads: 'Send Reads',
    items_add_inventory_success: 'Items added to inventory',
    new: 'New',
    confirm_mission: 'Confirm mission',
    confirm_mission_text: 'Are you sure you want to confirm the mission?',
    parziali: 'Partial',
    total_quantity: 'Total quantity',
    accepted_quantity: 'Accepted quantity',
    country_check: 'Country control',
    showcase_check: 'Showcase control',
    attention: 'Attention',
    clear_items: 'Proceeding will remove all readings, do you want to continue?',
    add_partial: 'Attention',
    add_partial_text: 'It may be necessary to create a Quality Assurance Mission',
    add_partial_already_text:
      'It may be necessary to create a Quality Assurance Mission. UMG already worked. Do you want to overwrite the items read?',
    error_cq_mission: 'Attention, create Mission for Quality Control',
    warning_zone_items: 'IN_ANOTHER_ZONE',
    internal_tag: 'Internal Tag',
    external_tag: 'External Tag',
    error_tag: 'Error tag',
    awaiting: 'Awaiting',
    error_logs: 'Error logs',
    event_logs: 'Event logs',
    box_closed: 'Box closed',
    unexpected_list: 'Unexpected item list',
    pending_tags: 'Pending tags',
    selected_zone: 'Selected zone',
    pricing: 'Pricing',
    send_to_sap: 'Send to SAP',
    write_tag: 'Write Tag',
    identifier_tag: 'Identifier Tag',
    digit_identifier_tag: 'Digit tag to write',
    target_epc: 'Target EPC',
    retry_write_tag: "E' stato riscontrato un errore nella scrittura. Vuoi riprovare a scrivere il tag?",
    ready: 'Ready',
    offline: 'Off-line',
    shipping_date: 'Shipping Date',
    update_only_product: 'Item already associated to another product. Are you sure do you want to overwrite it ?',
    export: 'Export',
    select_zone: 'Select zone',
    location_origin: 'Location origin',
  },
  error: {
    error: 'Error',
    errors: 'Errors',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type.',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Production Order "{{code}}" not found',
    no_inbound_zones: 'No Inbound zones',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    tag_not_found: 'Tag not found',
    missing_printer_id: 'Missing printer IP',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated. You must disassociate it first',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    workstation_could_not_be_started: 'Workstation {{code}} could not be started',
    authentication_failed: 'Authentication failed',
    field_required: 'Field {{label}} is required',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On.',
    items_in_error_found: 'Items in error found',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    connection: 'Connection error',
    connection_details:
      'There was no response from the server. Check that your internet connection or VPN configuration are working.',
    field_invalid_default: 'Invalid default value "{{defaultValue}}" for field {{field}}',
    field_invalid_default_from_endpoint: 'Could not finda a value for field {{field}}',
    sorting_already_read: 'Item with serial {{serial}} already read in group {{group}}',
    sorting_no_group: 'Item not sortable',
    sorting_not_in_zone: 'Item with serial {{serial}} not sortable. Already in zone {{zone}}',
    sorting_unknown: 'Tag {{tag}} is not valid',
    sorting_unexpected: 'Unexpected tag',
    no_item_configuration: 'No item configuration found',
    item_creation_error: 'Item creation error',
    couldn_find_production_order: "Couldn't find production order",
    couldn_find_production_order_row: "Couldn't find production order row",
    missing_operation_option: 'Missing operation option: {{error}}',
    item_session_list_create: 'Uncreated list',
    item_session_list_create_session: 'Uncreated session list',
    item_session_list_confirm: 'Unconfirmed list',
    item_session_list_confirm_session: 'Unconfirmed session list',
    form_validation: 'Validation form',
    form_validation_message: 'Error filling in the field',
    select_place_error: "Couldn't save place of origin",
    self_user_data: "Couldn't save your data",
    encoding_no_tags: 'No further tags have been read. Operation aborted',
    only_virgin_tag: 'Only virgin tags detected',
    tags_mismatch_error: 'Please read one tag at a time',
    tags_errors: 'Tag errors',
    value_not_valid: 'The entered value is not valid',
    write_tag: 'Unknown error during write Tag',
    WRONG_PRODUCTION_ORDER: 'Wrong Production Order',
    WRONG_PLACE: 'Wrong place',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: 'Production order row creation error',
    force_tag_associated: 'Tag already associated. Reassociate?',
    operation_confirm_error: 'Error confirming the operation',
    missing_mandatory_qc: 'No quality control. Proceed?',
    wrong_qc_status: 'The product has been declared KO. Proceed?',
    wrong_qc_size: 'Check size. Proceed?',
    wrong_qc_size_payload: 'Check size. Encoding Size: {{productSize}} - Physical size: {{manualSize}}. Proceed?',
    wrong_qc_model: 'Check model. Proceed?',
    start_checklist: 'Unstart checklist',
    confirm_with_unexpected: 'Cannot confirm with unexpected tags',
    confirm_with_overqty: 'Cannot confirm with over quantity tags',
    rfid_reader_initialization: 'Unknown error during RFID reader initialization',
    remove_sap_item_error: 'Unable to remove item',
    no_asn_selected: 'No ASN selected',
    submit_asn: 'Error submitting ASN',
    zbpNotFound: 'Zebra Browser Print agent not found on your system. Make sure it is installed and running',
    no_printer_selected: 'No printer selected',
    zplTemplateNotDefined:
      "ZPL template not defined. Please set a template code in the 'zpl-template-code' attribute of the operation configuration",
    no_print_template: 'Print template not found',
    wrong_tag: 'Wrong Tag',
    tag_error_or_not_authentic: 'Tag not authentic or authentication error',
    authentication_timeout: 'Authentication timeout',
    tid_missing: 'Tid for readed tag is missing',
    on_destination: 'on destination {{destination}}',
    item_not_sortable: 'Item with serial {{serial}} is not sortable',
    search_error: 'Search error',
    no_parcel_found_by_identifier: 'No parcel found with this identifiers',
  },
  messages: {
    no_operations_found: 'No operations found. Check the user permissions on the ControlPanel',
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    reading_in_progress: 'Reading in progress',
    waiting_for_product: 'Waiting for product...',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    pack_success: 'Packing successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    simple_item_list_success: 'Simple Item List successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
    generic_success: '{{code}} successfully confirmed',
    generic_success_canceled: '{{code}} successfully canceled',
    generic_error: '{{code}} error',
    fill_all_fields_and_search_an_item_configuration: 'Fill all fields and search an item configuration',
    encoding_success: 'Encoding successfully confirmed',
    encoding_success_none: 'Item already associated previously',
    read_some_identifier_to_start_verification: 'Read some identifier to start verification',
    warning_items_found: 'Warning items found',
    are_you_sure_you_want_to_confirm: 'Are you sure you want to confirm?',
    item_session_list_confirm: 'List confirmed successfully',
    operation_in_progress: 'Operation in progress, please wait...',
    sorting_error_zones: 'Origin zone not valued, impossible to continue with the ventilation',
    connection_in_progress: 'Connection in progress...',
    checklist_there_are: 'There are',
    checklist_missings: 'missing items',
    checklist_overqty: 'exceeding items',
    checklist_unexpecteds: 'unexpected items',
    checklist_confirm_mismatch_question: 'Are you sure you want to confirm anyway?',
    force_update_content: 'Are you sure you want to force send to SAP?',
    connection_to_station_in_progress: 'Connection to station in progress',
    tag_killed: 'Tag killed',
    tag_deleted: 'Tag deleted',
    write_tag: 'Write tag succesfully',
    not_ready: 'Not ready',
    readyf: 'Ready',
    nfcDecryption: 'NFC Decryption. Please keep the tag still',
  },
  placeholder: {
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel',
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
    confirm_delete_operation_title: 'Delete {{operation}}',
    confirm_delete_operation: 'Do you want to delete the operation? All items read will be lost.',
    confirm_delete_item_association: 'Are you sure you want to delete this item association?',
    confirm_kill_tag: 'Are you sure you want to kill this tag?',
    confirm_operation_title: 'Confirm {{operation}}',
    confirm_operation_text: 'Do you want to confirm the operation?',
    confirm_operation_text_irreversible: 'Do you want to confirm the operation? This action cannot be reverted.',
    confirm_operation_text_partial_confirm: 'Sending reading to JDE. Are you sure to continue?',
    post_confirm_action_title: 'Continue',
    post_confirm_action_keep_input: 'Operation complete. Perform another operation?',
    post_confirm_action_change_input: 'Operation complete. Perform another operation?',
    confirm_close_asn_title: 'Close Confirmation',
    confirm_close_asn_text:
      'Some parcels have not been received. Are you sure you want to FORCE the closing of this shipment?',
    unpack: 'Parcel already packed. Do you want to proceed with the unpack and packing again?',
  },
  labels: {
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'To receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit',
  },
  serverError: {
    //ZONE_MISMATCH_ERROR: 'The item is not located in the selected area of ​​origin',
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'DDT N.',
    creation_date: 'Date',
    capi_mdc: 'Capi MDC',
    qt_totale: 'Total Qty',
    qt_accettata: 'Accepeted Qty',
    qt_scartata: 'Rejected Qty',
    supplier: 'Supplier',
    notes: 'Notes',
  },
  custom: {
    countTBoxParcel: 'Parcel',
    countTBoxDDT: 'DDT',
    BARCODE_READ: 'Barcode Read',
    RFID_START_READING: 'Start reading',
    RFID_STOP_READING: 'Stop reading',
    RESULT_EVENT: 'Result event',
    TRACE: 'Trace',
    INFO: 'Info',
    WARNING: 'Warning',
    ERROR: 'Error',
    deleteSession: 'Delete Parcel',
    deleteSessionMessage: 'Are you sure you want to delete the parcel?',
    deleteList: 'Delete DDT',
    deleteListMessage: 'Are you sure you want to delete the ddt?',
    WAM: 'SKU',
    sap_warning: 'SAP warning',
    total_list: 'Total list',
    total_parcel: 'Total parcel',
    order_number: 'Order number',
    addon: 'Addon',
  },
  imbustatrice: {
    and: ' and ',
    unkwon_tag: 'Unkwon Tag',
    dynamic_tag: '{{role}} Tag',
    dynamic_tag_missing: '{{role}} Tag missing',
    too_many_dynamic_tags: 'Too many {{role}} tags',
    dynamic_tag_already_associated: '{{role}} tag already associated',
    wrong_tag: 'Wrong Tag',
    missing_tag: 'Tag missing',
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: 'Already associated',
    WRONG_QC_STATUS: 'Wrong quality control status',
    WRONG_QC_SIZE: 'Wrong quality control size',
    WRONG_QC_MODEL: 'Wrong quality control model',
    MISSING_MANDATORY_QC: 'Missing quality control',
    MISSING_PRODUCT_INFO: 'Missing product info',
    WRONG_TAG: 'Wrong tag',
    UNKNOWN_TAG: 'Unknown tag',
    WRONG_PRODUCT: 'Wrong product',
    MISSING_MANDATORY_TAG: 'Missing mandatory tag',
    MISSING_OPTIONAL_TAG: 'Missing optional tag',
    EXTRA_TAG: 'Extra tag',
    TAG_MISMATCH: 'Tag mismatch',
    PRODUCTIONORDERROW_MISMATCH: 'Mismatching Production Order Row Association',
    MISSING_SYSTEM_TAG: 'Unknown tag',
  },
  statuses: {
    RESTORE_VARIANT: 'RESTORE TYPOLOGY',
  },
}
